<template>
    <div style="width: 100vw; height: 100vh;">
        <vue-pdf v-if="urlBase64" :src="urlBase64" id="filePdf" ref="filePdf"></vue-pdf>
    </div>
</template>

<script>
import VuePdf from "vue-pdf";
export default {
    name: "reviewPDF",
    components: {
        VuePdf,
    },
    data() {
        return {
            url: "",
            urlBase64: "",
        };
    },
    async mounted() {
        this.url = this.$route.query.url;
        await fetch(this.url).then(response => response.blob()).then(res => {
            try {
                const b = new Blob([res]);
                let file = new FileReader();
                file.onload = (e) => {
                    this.urlBase64 = e.target.result;
                };
                file.readAsDataURL(b);
            } catch (e) {
                this.urlBase64 = "";
            }
        }).catch(() => {
            this.urlBase64 = "";
        });
    }
};
</script>

<style scoped>

</style>
